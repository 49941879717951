import {imageStatic} from "../../../helpers/image";
import Image from 'next/image';
import {ButtonHomePagetoSMM} from "../../Button";
import Link from 'next/link';
import { ChevronDoubleRightIcon } from "@heroicons/react/solid";
import news_lists from '../../../../constants/newsList'
import moment from 'moment/moment'
moment.locale('id')

const HomepageDesktop = () => {
    let backgroundSection1 = imageStatic('IMG-Sercice-Sosmed.webp')
    let backgroundSection3 = imageStatic('Group-444.webp')
    const currentNews = news_lists.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 3)

    return (
        <>
            <section className='homepage-section-1' style={{backgroundImage: `url(${backgroundSection1})`}}>
                <div className='md:container mx-auto relative min-height-100vh'>
                    <div
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-center'>
                        <h3 className='text-5xl font-bold block text-center text_color_white_1'>
                            Solusi Konten Sosial Media
                        </h3>
                        {/*<a href='#contentArea'*/}
                        {/*   className='inline-block bg_color_cream_1 text-4xl py-5 block px-16 text-center rounded-full font-bold color__green'>*/}
                        {/*    Apa Kebutuhan Anda*/}
                        {/*</a>*/}
                    </div>
                </div>
            </section>

            <section id='contentArea' className='homepage-section-2'>
                <div className='md:container mx-auto'>

                    <div className='grid grid-cols-12 '>
                        <div className='col-span-7 relative -z-1'>
                            <div className='grid grid-cols-2 gap-x-12 -mt-96'>
                                <div className='col-span-1 mt-72'>
                                    <div className='w-full mb-32'>
                                        <div className='image-wrap relative w-9/12 ml-auto'>
                                            <img src={`${imageStatic('sect1-video-3-web.webp')}`}
                                                 className='gif-image absolute w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-1'/>
                                            <Image width={343} height={596}
                                                   src={`${imageStatic('Group-452.png')}`}
                                                   className='frame-image h-full'/>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='image-wrap relative w-9/12 mr-auto'>
                                            <img src={`${imageStatic('sect1-video-1-web.webp')}`}
                                                 className='gif-image absolute w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-1'/>
                                            <Image width={343} height={596}
                                                   src={`${imageStatic('Group-452.png')}`}
                                                   className='frame-image h-full'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-1 col-span-1 mt-96 pt-40'>
                                    {/*<div className='w-full mb-24'>*/}
                                    {/*    <div className='image-wrap relative w-10/12 mx-auto'>*/}
                                    {/*        <img src='/img/gif/SB_4.webp' className='gif-image absolute w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-1' />*/}
                                    {/*        <img src={`${imageStatic('Group-452.png')}`} className='frame-image w-full' />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className='w-full'>
                                        <div className='image-wrap relative w-full mr-auto'>
                                            <img src={`${imageStatic('sect1-video-2-web.webp')}`}
                                                 className='gif-image absolute w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-1'/>
                                            <Image width={407} height={706}
                                                   src={`${imageStatic('Group-452.png')}`}
                                                   className='frame-image w-full'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-5 relative'>
                            <div
                                className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-right w-full'>
                                <h3 className='text-4xl block rounded-full font-bold color__green block shadow-text-1 mb-5'>
                                    Social Media Management
                                </h3>
                                <p className='text-sz-32 color__green block mb-5'>
                                    Buat konten social media Anda,<br/> tanpa repot.
                                </p>
                                <ButtonHomePagetoSMM/>
                            </div>
                        </div>
                    </div>

                    {/*start section 2 live*/}
                    <div className='grid grid-cols-12'>
                        <div className='col-span-5 relative'>
                            <div
                                className='absolute top-1/2 -right-1/2 transform -translate-x-1/4 -translate-y-1/2 text-right w-full'>
                                <h3 className='text-5xl block rounded-full font-bold color__green block shadow-text-1 mb-5'>
                                    Live Shopping
                                </h3>
                                <p className='text-4xl color__green block mb-5'>
                                    Berjualan Lewat<br/> Live Shopping.
                                </p>
                                <p className='text-xl color__green block mb-5'>
                                    Tingkatkan penjualan dan promosikan produk Anda<br/>
                                    ke lebih banyak orang melalui live shopping.
                                </p>
                                <a href='/live-shopping' className='bg_color_cream_1 text md:text-4xl py-5 px-16 block text-center rounded-full font-bold color__green inline-block shadow-button-1 block mb-4'>
                                    Coba Sekarang
                                </a>
                            </div>
                        </div>
                        <div className='col-span-7 relative -z-1'>
                            <div className='grid grid-cols-1 gap-x-12 -mt-96'>
                                <div className='col-span-1 col-span-1 mt-96 pt-40 w-5/12 mx-auto justify-center'>
                                    <div className='w-full'>
                                        <div className='image-wrap relative w-full mx-auto'>
                                            {/*<img src={`${imageStatic('gif/SB_2.webp')}`}*/}
                                            {/*     className='gif-image absolute w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-1'/>*/}
                                            {/*<Image width={407} height={706}*/}
                                            {/*       src={`${imageStatic('Group-452.png')}`}*/}
                                            {/*       className='frame-image w-full'/>*/}
                                            <img src="live-shopping/pictn-desk-section2-home.png" className="img img-fluid w-full" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end section 2 live*/}

                    <div className='grid grid-cols-8 gap-x-16 items-center mt-200px'>
                        <div className='col-span-3 relative'>
                            <div className='grid'>
                                <h3 className='text-4xl block rounded-full font-bold color__green block shadow-text-1 mb-5'>
                                    Endorse Influencer
                                </h3>
                                <p className='text-sz-32 color__green block mb-5 w-4/6 block'>
                                    Kenalkan produk Anda,<br/> melalui Influencers yang tepat.
                                </p>
                                <Link href='/endorse-page'>
                                    <a className='bg_color_cream_1 text-4xl py-5 block text-center rounded-full font-bold color__green inline-block shadow-button-1 mb-4 w-9/12'>
                                        Cari Sekarang
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className='col-span-5'>
                            <Image width={992} height={897} src={`${imageStatic('Group_437.webp')}`}
                                   className='-mt-24'/>
                        </div>
                    </div>
                    <div className='smaller-container-1 mx-auto mt-44'>
                        <div className='flex justify-end'>
                            <Image width={275} height={378} src={`${imageStatic('Group-440.png')}`}
                                   className=''/>
                            <div className='ml-10 color__green w-6/12 self-end'>
                                <h3 className='text-3xl leading-normal mb-8'>
                                    “Social Bread membantu brand Kebab Turki Baba Rafi viral di
                                    TikTok
                                    hingga 2 juta views! Gokil banget!”
                                </h3>
                                <p className='text-lg'>
                                    Hendy Setiono
                                </p>
                                <p className='text-lg'>
                                    Founder Baba Rafi Enterprise
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='smaller-container-1 mx-auto mt-44'>
                        <div className='flex justify-items-start'>
                            <Image width={430} height={264} src={`${imageStatic('Group-441.png')}`}
                                   className=''/>
                            <div className='ml-10 color__green w-7/12 self-end'>
                                <h3 className='text-3xl leading-normal mb-8'>
                                    “Berkat Social Bread, brand saya mencapai 68 ribu followers
                                    dalam 3
                                    bulan”
                                </h3>
                                <p className='text-lg'>
                                    Donny Purnomo
                                </p>
                                <p className='text-lg mb-6'>
                                    Founder Sour Sally Group
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='homepage-section-3 mt-32 mb-32'>
                <h3 className='text-4xl block rounded-full font-bold color__green shadow-text-1 mb-12 text-center'>
                    In the Press
                </h3>
                <div className="flex flex-col justify-center items-center px-4">
                    <div className="grid grid-cols-3 gap-4">
                        {currentNews.map((item, index) => {
                            return (
                                <div className="flex w-full justify-center items-center" key={index}>
                                <div className="max-w-sm bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700">
                                    <img className="" src={item.image} style={{height: 200, width: "100%", objectFit: "cover"}} alt="" />
                                    <div className="p-5">
                                        <span className="text-sm">{moment(item.date).format('DD MMMM YYYY')}</span>
                                        <h5 className="mb-2 mt-1 text-md font-bold tracking-tight color__green">{item.headline}</h5>
                                        <div className='mb-3 font-normal text-gray-700 dark:text-gray-400 demo-1' dangerouslySetInnerHTML={{__html: item.content}} />
                                        <div className="flex justify-end">
                                            <a href={item.link} target="_blank" className="font-bold inline-flex items-center px-3 py-2 text-sm text-center focus:outline-none color__green">
                                                Read more
                                                <ChevronDoubleRightIcon width={20} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                    <Link href='/newsroom'>
                        <span className='bg_color_cream_1 cursor-pointer text-xl px-7 py-2 mt-12 text-center rounded-full font-bold color__green inline-block shadow-button-1'>
                            Selengkapnya
                        </span>
                    </Link>
                </div>
            </section>
            <section className='homepage-section-3 mt-32 mb-32 flex items-center px-4 justify-center'>
                <div className=" flex flex-col h-[300px] bg-orangeg w-full items-center justify-center" style={{ borderRadius: 30, maxWidth: 1400, height: 300}}>
                    <h1 className='color__green md:text-3xl text-2xl font-bold mb-2'>Business Partners of Leading Platforms</h1>
                    <div className=" flex flex-row gap-10 items-center">
                        <div className=" w-[200px]">
                            <img src="home/tiktok-1.png" alt="" className="img img-fluid"/>
                        </div>
                        <div className=" w-[200px]">
                            <img src="home/tiktok-2.png" alt="" className="img img-fluid"/>
                        </div>
                        <div className=" w-[200px]">
                            <img src="home/shopee-1.png" alt="" className="img img-fluid"/>
                        </div>
                        <div className=" w-[200px]">
                            <img src="home/shopee-2.png" alt="" className="img img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className='homepage-section-3 mt-44'
                     style={{backgroundImage: `url(${backgroundSection3})`}}>
                <div className='md:container mx-auto relative min-height-100vh'>
                    <div
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-center w-full'>
                        <h3 className='text-4xl text_color_white_1 mb-4'>
                            Dipercaya oleh lebih dari 150 brand lokal.
                        </h3>
                        <h3 className='text-5xl font-bold text_color_white_1'>
                            Mari bergabung sekarang!
                        </h3>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default HomepageDesktop
