import {
    CHECK_ORDER,
    GET_PAYMENT_METHOD_LIST,
    GET_PAYOUT_BANK_LIST,
    LOADING_PAYMENT_ORDER_SMM,
    PAYMENT_ORDER_SMM,
    LOADING_PAYMENT_ORDER_KOL,
    PAYMENT_ORDER_KOL,
    GET_USER_BANK_DATA,
} from '../../action/payment/types';

const initialState = {
    paymentMethodData: [],
    paymentData: {
        data: {},
        message: '',
        loading: false,
        error: false,
    },
    transactionData: {
        data: {},
        message: '',
        error: false,
    },
    userBankData:{
        data:{},
        message: '',
        error: false,
    },
    payoutBankList: [],
};

const payment = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_PAYMENT_METHOD_LIST:
            return {
                ...state,
                paymentMethodData: action.payload.data,
            };
        case GET_PAYOUT_BANK_LIST:
            return {
                ...state,
                payoutBankList: action.payload.data,
            };
        case GET_USER_BANK_DATA:
            return{
                ...state,
                userBankData: action.payload.data,
            }
        case PAYMENT_ORDER_SMM:
            return {
                ...state,
                paymentData: {
                    ...state.paymentData,
                    data: action.payload.data,
                    message: action.payload ? action.payload.message : state,
                    error: action.payload.error,
                },
            };
        case LOADING_PAYMENT_ORDER_SMM:
            return {
                ...state,
                paymentData: {
                    ...state.paymentData,
                    loading: !state.paymentData.loading,
                },
            };
        case PAYMENT_ORDER_KOL:
            return {
                ...state,
                paymentData: {
                    ...state.paymentData,
                    data: action.payload.data,
                    message: action.payload ? action.payload.message : state,
                    error: action.payload.error,
                },
            };
        case LOADING_PAYMENT_ORDER_KOL:
            return {
                ...state,
                paymentData: {
                    ...state.paymentData,
                    loading: !state.paymentData.loading,
                },
            };
        case CHECK_ORDER:
            return {
                ...state,
                transactionData: {
                    ...state.transactionData,
                    data: action.payload.data,
                    message: action.payload ? action.payload.message : state,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
};

export default payment;
