import {GETPRICESMM} from "../../action/getOrderSmm/types";

const initialState = {
    message: '',
    dataPriceSmm: [],
};

const dataPriceSmm = (state = initialState, action = {}) => {
    if (action.type === GETPRICESMM) {
        return {
            ...state,
            dataPriceSmm: action.payload.dataPriceSmm,
            message: action.payload ? action.payload.message : state,
        }
    } else {
        return state
    }
}

export default dataPriceSmm;
