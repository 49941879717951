import {AUTHENTICATE, AUTHENTICATE_ERROR, AUTHENTICATE_STATUS} from "../../action/login/types";

const initialState = {
    message: '',
    next: false
};

const loginUsers = (state = initialState, action = {}) => {

    switch (action.type) {

        case AUTHENTICATE:
            return {
                ...state,
                message: action.payload ? action.payload.message : state,
                next: true
            }
        case AUTHENTICATE_ERROR:
            return {
                ...state,
                message: action.payload ? action.payload.message : state,
                next: false
            }
        case AUTHENTICATE_STATUS:
            return {
                ...state,
            }
        default :
            return state
    }
}

export default loginUsers;
