import {GETINFLUENCERDETAIL, GETINFLUENCERDETAIL_ERR, GETCARTNAME} from "../../action/influencerDetail/types";

const initialState = {
    message: '',
    dataInfluencerDetail: [],
    dataCartName: [],
};

const dataInfluencerDetail = (state = initialState, action = {}) => {

    switch (action.type) {

        case GETINFLUENCERDETAIL:
            return {
                ...state,
                dataInfluencerDetail: action.payload.dataInfluencerDetail,
                message: action.payload ? action.payload.message : state,
            }
        case GETINFLUENCERDETAIL_ERR:
            return {
                ...state,
                message: action.payload ? action.payload.message : state,
            }
        default :
            return state
    }
}

export default dataInfluencerDetail;
