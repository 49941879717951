import {useEffect, useState} from "react";
import NavbarAfterLogin from "./navbarAfterLogin";
import NavbarBeforeLogin from "./navbarBeforeLogin";
import Cookies from 'js-cookie'


const Navbar = () => {
    const [login, setLogin] = useState(Cookies.get('token'))
    const [role, setRole] = useState(Cookies.get('role'))
    const [first, setFirst] = useState(Cookies.get('first'))
    const [isLogin, setisLogin] = useState(false)
    useEffect(() => {
        if (first === true && login === 'undefined' && role === 'undefined') {
            setisLogin(false)
        } else if (!login && !role) {
            setisLogin(false)
        } else if (login !== 'undefined' && role !== 'undefined') {
            setisLogin(true)
        }
    }, [setLogin])

    return (
        <div>
            {isLogin ? (
                    <NavbarAfterLogin/>
                )
                :
                <NavbarBeforeLogin/>
            }
        </div>
    )
}

export default Navbar;
