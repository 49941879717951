import {GET_SEM_WP,} from "../../action/semWp/types";


const initialState = {
    getSemWpResult: false,
    getSemWpLoading: false,
    getSemWpError: false
};

const semWp = (state = initialState, action = {}) => {

    if (action.type === GET_SEM_WP) {
        return {
            ...state,
            getSemWpResult: action.payload.data,
            getSemWpLoading: action.payload.loading,
            getSemWpError: action.payload.errorMessage
        }
    } else {
        return state
    }
}

export default semWp;
