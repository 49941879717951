import React, {useEffect, useState} from "react";
import {useRouter} from 'next/router'
import OtpInput from 'react-otp-input';
import {loginUser, otpStatus, sendVerifyOtp} from "../../redux/action/login";
import {useDispatch, useSelector} from "react-redux";
import {fetchDataKotaAction} from "../../redux/action/verification-login";


const VerificationLogin = (data) => {
    const router = useRouter()
    const {next, first_login} = useSelector((state) => state.loginUserOtpReducer)

    const [otp, setOtp] = useState("");
    const [counter, setCounter] = useState(120)
    const [countDown, setCountDown] = useState(false)
    const [errorMessageBool, setErrorMessageBool] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const dispatch = useDispatch()

    const notel = localStorage.getItem("noHp");

    const handleLogin = (event) => {
        event.preventDefault()
        if (otp.length === 6) {
            dispatch(fetchDataKotaAction());
            dispatch(sendVerifyOtp({whatsapp_number: notel, whatsapp_token: otp}))
            setButtonDisabled(true)
        } else {
            setErrorMessageBool(true)
            setButtonDisabled(false)

        }
    }

    const getDataOtp = (otpNumber) => {
        setOtp(otpNumber)
    }

    useEffect(() => {
        if (!next) {
            setErrorMessageBool(true);
        } else {
            if (first_login) {
                notel = '62' + notel
                router.push({
                    pathname: '/verification-login',
                    query: {noHp: notel},
                });
            }
            
            if (typeof first_login === "boolean" && first_login === false) {
                router.reload();
            }
        }
    }, [next, dispatch]);

    const sendOTP = (event) => {
        event.preventDefault()
        dispatch(loginUser({ wa_number: notel }))
        setCountDown(false)
        setCounter(120)
    }

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if (counter === 0) {
            setCountDown(true)
        }
    }, [counter]);

    useEffect(() => {
        dispatch(otpStatus())
    }, [dispatch])


    return (
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none verify-modal-area"
        >
            <div className="relative w-full px-2 my-6 mx-auto max-w-xl">
                {/* content*/}
                <div
                    className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="login-by-whatsapp">
                        {/*header */}
                        <div
                            className="flex items-start justify-between py-5 px-3 md:pt-11 md:px-14 border-solid border-blueGray-200 rounded-t">
                            <h3 className="md:text-3xl text-2xl font-semibold">
                                Verifikasi Akun
                            </h3>
                            {/*<button*/}
                            {/*    className=""*/}
                            {/*    onClick={closeVerifyModal}*/}
                            {/*>*/}
                            {/*    <img src={`${imageStatic('icon/Close-Button.png')}`}*/}
                            {/*        className="w-full"></img>*/}
                            {/*</button>*/}
                        </div>
                        {/*body*/}
                        <div
                            className="relative md:pt-9 py-5 px-3 md:pb-auto md:px-14 flex-auto text-center verification-modal">
                            <h3 className="text-xs md:text-lg font-bold text_color_green_3 mb-2">
                                Verifikasi Login
                            </h3>
                            <p className="text-xs md:text-lg font-normal text_color_green_3 mb-5">
                                Kode Verifikasi terlah terkirim +62{notel}
                            </p>
                            <div
                                className="verification-pin-container flex flex-wrap md:mt-9 md:w-full w-11/12 mx-auto">
                                <OtpInput
                                    className="otp-input-styling"
                                    containerStyle="md:w-full"
                                    inputStyle="border"
                                    numInputs="6"
                                    separator={<span style={{ marginLeft: "20px" }} />}
                                    isInputNum="true"
                                    shouldAutoFocus
                                    value={otp}
                                    onChange={getDataOtp}
                                />
                                {errorMessageBool ? (<>
                                    <label className="otp-warning-label">
                                        Harap masukan kode OTP yang benar
                                    </label>
                                </>) : null}
                            </div>
                            {/*footer*/}
                            <div
                                className="justify-end md:py-11 py-5 px-3 md:px-14 border-solid border-blueGray-200 rounded-b md:pt-9">
                                <button
                                    className="text-lg text_color_white font-medium bg_color_green_2 py-3  text-center w-full rounded-xl block mb-5"
                                    type="button"
                                    onClick={handleLogin}
                                    disabled={buttonDisabled}
                                >
                                    Verifikasi
                                </button>
                            </div>

                            {countDown ? (<>
                                <button
                                    className="disabled text-lg color__green font-medium bg_color_green_6 py-3 text-center w-full rounded-xl block "
                                    type="button"
                                    onClick={sendOTP}
                                >
                                    Kirim Ulang Kode Verifikasi
                                </button>
                            </>) : <>
                                <div className="md:mt-4 mt-2 text-xs md:text-base">
                                    Silahkan tunggu <span className="text_color_green_3">{counter}</span> Detik
                                    untuk mengirim ulang
                                    kode verifikasi
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerificationLogin;
