import Head from 'next/head'

export default function Meta(props) {
    const {title, description, keywords, ogTitle, ogDescription, ogType, ogUrl} = props

    return (
        <Head>
            <title>{title || "Marketplace Influencer untuk UMKM | socialbread.id"}</title>
            <meta charSet="utf-8"/>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, shrink-to-fit=no"/>
            <meta name="apple-mobile-web-app-capable" content="yes"/>
            <meta name="apple-mobile-web-app-title" content="socialbread.id"/>
            <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
            <meta name="title" content={title || "Marketplace Influencer untuk UMKM | socialbread.id"}/>
            <meta name="description" content={description || "Marketplace Influencer untuk UMKM; Indonesia"}/>
            <meta name="keywords" content={keywords || "Marketplace Influencer untuk UMKM | socialbread.id"}/>
            <meta name="theme-color" content="#F82D2C"/>
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:title" content={title || "Marketplace Influencer untuk UMKM | socialbread.id"}/>
            <meta property="title"/>
            <meta property="og:title" content={ogTitle || "Marketplace Influencer untuk UMKM; Indonesia"}
                  key="ogtitle"/>
            <meta property="og:description" content={ogDescription || "Marketplace Influencer untuk UMKM; Indonesia"}
                  key="ogdescription"/>
                <meta property="og:type" content={ogType || "website"} key="ogtype"/>
                <meta property="og:url" content={ogUrl || "https://socialbread.id/"} key="ogurl"/>
            {/*<meta property="og:image" content={ogImage || "https://assets.adira.one/sp/icons/logo3.svg"} key="ogimage"/>*/}
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:title" content={ogTitle || "Marketplace Influencer untuk UMKM; Indonesia"}
                  key="ogtitle"/>
                {/*<meta property="twitter:image" content={ogImage || "https://assets.adira.one/sp/icons/logo3.svg"} />*/}
                <meta property="twitter:url" content={ogUrl || "https://socialbread.id/"} key="ogurl"/>
                <link rel="canonical" href={ogUrl || "https://socialbread.id/"} key="ogurl"/>

                {/*facebook*/}
                <meta name="facebook-domain-verification" content="016d4ny7ogap1sd7gpuks85yzsm4i5"/>
                <link rel="manifest" href="/manifest.json"/>

                <link rel="icon" type="image/x-icon" href="/icon-192x192.png"></link>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-DRRWEP6JKJ"/>

            <script dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-DRRWEP6JKJ');
            `
            }}/>

            <script dangerouslySetInnerHTML={{
                __html: `
                 !function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
    
                    ttq.load('CDKC7HJC77UEMNFQ05J0');
                    ttq.page();
                }(window, document, 'ttq');
                `
            }}>
            </script>
        </Head>
    )
}
