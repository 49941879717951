import {CLICK_BEFORE_ADD_TO_CART, GET_SERVICE_CLICK} from "../../action/addToCart/types";

const initialState = {
    serviceClick: [],
};

const addToCart = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLICK_BEFORE_ADD_TO_CART:
            return {
                ...state,
                serviceClick: [...state.serviceClick, action.payload]
            };
        case GET_SERVICE_CLICK:
            const a = {
                ...state,
                serviceClick: [...state.serviceClick]
            }
            console.log(a);
            return {
                ...state,
                // serviceClick: {
                //     ...state.serviceClick,
                // }
            };
        default:
            return state;
    }

}

export default addToCart;
