import {GET_ALL,} from "../../action/aboutUs/types";


const initialState = {
    getAboutResult: false,
    getAboutLoading: false,
    getAboutError: false
};

const aboutUs = (state = initialState, action = {}) => {

    if (action.type === GET_ALL) {
        return {
            ...state,
            getAboutResult: action.payload.data,
            getAboutLoading: action.payload.loading,
            getAboutError: action.payload.errorMessage
        }
    } else {
        return state
    }
}

export default aboutUs;
