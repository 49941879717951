import React, {useState} from 'react';
import {useRouter} from 'next/router';
import {loginUser} from '../../../src/redux/action/login';
import {useDispatch, useSelector} from 'react-redux';
import VerificationLogin from './verificationLogin';
import {imageStatic} from "../../helpers/image";

const ModalLogin = (props) => {
    const router = useRouter();

    const {message, next} = useSelector((state) => state.loginUserReducer);

    const [showModal, setShowModal] = useState(false);
    const [showModalVerify, setShowModalVerify] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    const [noHp, setNoHp] = useState('');
    const [errorMessageInvalidWa, setErrorMessageInvalidWa] = useState('');
    const [errorMessageNull, setErrorMessageNull] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const dispatch = useDispatch();

    const handleHoHp = (event) => {
        event.preventDefault();
        setNoHp(event.target.value);
        const rgxwa = /^(^\+62|62|^8)(\d{3,4}-?){2}\d{3,4}$/g;

        if (!rgxwa.test(noHp)) {
            setErrorMessageInvalidWa('No Hp tidak sesuai');
            setValidNumber(false);
            return false;
        } else if (setErrorMessageInvalidWa) {
            setErrorMessageInvalidWa('');
            setValidNumber(true);
        }
        return true;
    };

    const focusRgx = (event) => {
        event.preventDefault();
        // setNoHp(event.target.value)
        const hp = event.target.value;
        const rgxwa = /^(^\+62|62|^8)(\d{3,4}-?){2}\d{3,4}$/g;

        if (!rgxwa.test(hp)) {
            setErrorMessageInvalidWa('No Hp tidak sesuai');
            setValidNumber(false);
            return false;
        } else if (setErrorMessageInvalidWa) {
            setErrorMessageInvalidWa('');
            setValidNumber(true);
        }
        return true;
    };

    const openModal = (event) => {
        event.preventDefault();

        router.push('/login');
        // if (isMobileOnly) {
        // } else {
        //     setShowModal(true);
        // }
    };

    let status;

    setTimeout(
        (() => {
            if (!next) {
                status = false;
                setErrorMessageInvalidWa(message);
            } else {
                status = true;
                if (status) {
                    setShowModal(false);
                    // setShowModalVerify(true);
                }
            }
        }).bind(this),
        1000
    );

    const handleLogin = (event) => {
        event.preventDefault();
        if (validNumber) {
            localStorage.setItem("noHp", noHp);
            dispatch(loginUser({nohp: noHp}));
            setButtonDisabled(true);
        }
        if (!noHp) {
            setErrorMessageNull('Harap Masukkan Nomor Whatsapp');
            setButtonDisabled(false)
        }
    };

    return (
        <>
            <button
                className="md:text-sm text-sm py-1 px-2 rounded-md md:block text_color_green_2 md:py-2 border_color_green_2 md:text-center md:rounded-md md:w-full"
                type="button"
                onClick={openModal}
            >
                Login / Daftar
            </button>
            {/*<img*/}
            {/*    src={`${imageStatic('icon/Vector-4-mobile-2.png')}`}*/}
            {/*    className="md:hidden block cursor-pointer"*/}
            {/*    onClick={openModal}*/}
            {/*/>*/}
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none login-modal">
                        <div className="relative w-full my-6 px-2 mx-auto max-w-xl">
                            {/* content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="login-by-whatsapp ">
                                    {/*header */}
                                    <div className="flex items-start justify-between md:py-auto py-5 px-3 md:pt-11 md:px-14 border-solid border-blueGray-200 rounded-t">
                                        <h3 className="md:text-3xl text-2xl font-semibold">
                                            Masuk Ke Akun
                                        </h3>
                                        <button
                                            className=""
                                            onClick={() => setShowModal(false)}
                                        >
                                            <img
                                                src={`${imageStatic('icon/Close-Button.png')}`}
                                                className="w-full"
                                            ></img>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative md:pt-11 md:px-14 py-5 px-3 flex-auto">
                                        <h3 className="text-xs md:text-lg font-medium text_color_green_3 mb-2">
                                            Nomor Whatsapp
                                        </h3>
                                        <div className="input-container-for-prefix relative">
                                            <span className="absolute left-6 transform text-xs md:text-base -translate-x-1/2 top-1/2 -translate-y-1/2">
                                                +62
                                            </span>
                                            <input
                                                className="appearance-none border pl-10 md:pl-12 w-full py-2  md:py-4 text-xs md:text-base px-2 md:px-5 text-gray-700 leading-tight
                                                focus:outline-none focus:shadow-outline rounded md:rounded-xl"
                                                onChange={handleHoHp}
                                                value={noHp}
                                                onBlur={focusRgx}
                                                autoComplete="off"
                                                id="noHp"
                                                type="tel"
                                                placeholder="815*****875"
                                            ></input>
                                        </div>
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {errorMessageInvalidWa ||
                                                errorMessageNull}
                                        </span>
                                    </div>
                                    {/*footer*/}
                                    <div className="justify-end md:py-11 md:px-14 py-5 px-3 border-solid border-blueGray-200 rounded-b">
                                        {/* <button
                                    className="text-base  md:text-lg text_color_white font-medium bg_color_green_2 py-3  text-center w-full rounded-xl block mb-5"
                                    type="button"
                                    // onClick={handleLogin}
                                >
                                    Login
                                </button> */}

                                        <button
                                            className="text-base  md:text-lg text_color_white font-medium bg_color_green_2 py-3  text-center w-full rounded-xl block mb-5"
                                            type="button"
                                            onClick={handleLogin}
                                            disabled={buttonDisabled}
                                        >
                                            Login
                                        </button>
                                        {/*<Link href="/daftar-owner">*/}
                                        {/*    <button*/}
                                        {/*        className="md:text-lg text-base color__green font-medium bg_color_green_6 py-3  text-center w-full rounded-xl block"*/}
                                        {/*        type="button"*/}
                                        {/*    >*/}
                                        {/*        Daftar Sekarang*/}
                                        {/*    </button>*/}
                                        {/*</Link>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showModalVerify ? (
                <VerificationLogin notel={noHp} />
            ) : null}
        </>
    );
};

export default ModalLogin;
