import {GET_CONTACT_US,} from "../../action/contactUs/types";

const initialState = {
    getContactUsResult: false,
    getContactUsLoading: false,
    getContactUsError: false
};


const contactUs = (state = initialState, action = {}) => {

    if (action.type === GET_CONTACT_US) {
        return {
            ...state,
            getContactUsResult: action.payload.data,
            getContactUsLoading: action.payload.loading,
            getContactUsError: action.payload.errorMessage
        }
    } else {
        return state
    }
}

export default contactUs;
