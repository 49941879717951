import HomePage from './homepage'

function Home() {
    return (
        <div>
            <HomePage/>
        </div>

    )
}
export default Home;