import {
    FILTER_KATEGORI_HARGA_MAX,
    FILTER_KATEGORI_HARGA_MIN,
    FILTER_KATEGORI_INFLUENCER,
    FILTER_KATEGORI_LOKASI,
    FILTER_KATEGORI_SOSMED,
    FILTER_KATEGORI_USAHA,
    FILTER_RESET,
} from '../../action/influencer/types';

const initialState = {
    filterKategoriUsaha: [],
    filterKategoriLokasi: [],
    filterKategoriInfluencer: [],
    filterKategoriSosmed: [],
    filterKategoriHargaMin: '',
    filterKategoriHargaMax: '',
};

const dataFilterInfluencerMobile = (state = initialState, action = {}) => {
    switch (action.type) {
        case FILTER_KATEGORI_LOKASI:
            return {
                ...state,
                filterKategoriLokasi: action.payload,
            };
        case FILTER_KATEGORI_USAHA:
            return {
                ...state,
                filterKategoriUsaha: action.payload,
            };
        case FILTER_KATEGORI_INFLUENCER:
            return {
                ...state,
                filterKategoriInfluencer: action.payload,
            };
        case FILTER_KATEGORI_SOSMED:
            return {
                ...state,
                filterKategoriSosmed: action.payload,
            };
        case FILTER_KATEGORI_HARGA_MIN:
            return {
                ...state,
                filterKategoriHargaMin: action.payload,
            };
        case FILTER_KATEGORI_HARGA_MAX:
            return {
                ...state,
                filterKategoriHargaMax: action.payload,
            };
        case FILTER_RESET:
            return initialState;
        default:
            return state;
    }
};

export default dataFilterInfluencerMobile;
