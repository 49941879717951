import {GET_FAQ_WP,} from "../../action/faq/types";

const initialState = {
    getFaqResult: false,
    getFaqLoading: false,
    getFaqError: false
};


const faqWp = (state = initialState, action = {}) => {

    if (action.type === GET_FAQ_WP) {
        return {
            ...state,
            getFaqResult: action.payload.data,
            getFaqLoading: action.payload.loading,
            getFaqError: action.payload.errorMessage
        }
    } else {
        return state
    }
}

export default faqWp;
