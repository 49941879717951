import {GETPRICESMMCOMPLETE} from "../../action/getOrderSMMComplete/types";

const initialState = {
    message: '',
    dataPriceSmmComplete: [],
};

const dataPriceSmmComplete = (state = initialState, action = {}) => {
    if (action.type === GETPRICESMMCOMPLETE) {
        return {
            ...state,
            dataPriceSmmComplete: action.payload.dataPriceSmmComplete,
            message: action.payload ? action.payload.message : state,
        }
    } else {
        return state
    }
}

export default dataPriceSmmComplete;
