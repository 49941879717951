import {
    AUTHENTICATE,
    AUTHENTICATE_ERROR,
    AUTHENTICATE_ERROR_OTP,
    AUTHENTICATE_OTP,
    AUTHENTICATE_STATUS,
    AUTHENTICATE_STATUS_OTP
} from './types';
import {loginurl, otpurl} from "../../../service/login/index.jsx";
import axios from 'axios';
import Cookies from 'js-cookie'

export const loginUser = (datas) => {
    return async (dispatch) => {
        try {
            const {data} = await axios.post(loginurl, {wa_number: "62" + datas.noHp})
            dispatch({
                type: AUTHENTICATE,
                payload: {
                    message: data.message
                }
            })
        } catch (err) {
            if (!err.response) {
                dispatch({
                    type: AUTHENTICATE_ERROR,
                    payload: {
                        message: 'no connection to server'
                    }
                })
            }

            const res = err.response.data
            dispatch({
                type: AUTHENTICATE_ERROR,
                payload: {
                    message: res.message
                }
            })

        }
    }
}

export const loginStatus = () => {

    return (dispatch) => {
        dispatch({
            type: AUTHENTICATE_STATUS,
        })
    }
};

export const otpStatus = () => {

    return (dispatch) => {
        dispatch({
            type: AUTHENTICATE_STATUS_OTP,
        })
    }
};


export const sendVerifyOtp = (datas) => {
    return async (dispatch) => {
        try {
            const {data} = await axios.post(otpurl, {
                whatsapp_number: "62" + datas.whatsapp_number,
                whatsapp_token: datas.whatsapp_token
            })
            const token = data.data.token;
            const first_login = data.data.first_login
            if (first_login) {
                Cookies.set('first', first_login, {secure: true});
            } else {
                Cookies.set('token', token, {secure: true}, {expires: 7, domain: window.location.hostname});
                Cookies.set('role', data.data.role, {secure: true}, {expires: 7});
            }

            dispatch({
                type: AUTHENTICATE_OTP,
                payload: {
                    message: data.message,
                    first_login
                }
            })
        } catch (err) {
            if (!err.response) {
                dispatch({
                    type: AUTHENTICATE_ERROR_OTP,
                    payload: {
                        message: 'no connection to server'
                    }
                })
            }

            const res = err.response.data
            dispatch({
                type: AUTHENTICATE_ERROR_OTP,
                payload: {
                    message: res.message
                }
            })
        }
    }
}
