import {
    GET_KATEGORI_LOKASI,
    GET_KATEGORI_SOSMED,
    GET_KATEGORI_USAHA,
    GET_KOTA_BY_PROVINCE,
    GET_PROVINCE
} from "../../action/filter/types";

const initialState = {
    message: '',
    dataKategoriUsaha: [],
    dataKategoriLokasi: [],
    dataKategoriSosmed: [],
    dataProvince: [],
    dataKotaByProvince: [],
};

const dataKategoriUsaha = (state = initialState, action = {}) => {

    switch (action.type) {
        case GET_KOTA_BY_PROVINCE:
            return {
                ...state,
                dataKotaByProvince: action.payload.dataKotaByProvince,
                message: action.payload ? action.payload.message : state,
            }
        case GET_PROVINCE:
            return {
                ...state,
                dataProvince: action.payload.dataProvince,
                message: action.payload ? action.payload.message : state,
            }
        case GET_KATEGORI_USAHA:
            return {
                ...state,
                dataKategoriUsaha: action.payload.dataKategoriUsaha,
                message: action.payload ? action.payload.message : state,
            }
        case GET_KATEGORI_LOKASI:
            return {
                ...state,
                dataKategoriLokasi: action.payload.dataKategoriLokasi,
                message: action.payload ? action.payload.message : state,
            }
        case GET_KATEGORI_SOSMED:
            return {
                ...state,
                dataKategoriSosmed: action.payload.dataKategoriSosmed,
                message: action.payload ? action.payload.message : state,
            }
        default :
            return state
    }
}

export default dataKategoriUsaha;
