import React from "react";
import Link from 'next/link'
import Image from 'next/image'
import {imageStatic} from "../helpers/image";
import Router from "next/router";
import Cookies from "js-cookie";
import {Menu} from "@headlessui/react";
import { useRouter } from 'next/router';

const NavbarAfterLogin = () => {
    const { asPath } = useRouter();
    const handleLogout = () => {
        Cookies.remove("token");
        Cookies.remove("role");

        Router.reload();
        Router.push("/", null, {shallow: true});
    }

    const toProfile = () => {
        Router.push("/profile");
    }

        return (
            <div>
                <div
                    className="not-registered-navbar hidden md:block md:shadow-lg md:absolute md:w-full md:z-50 bg_color_white_1">
                    <div className="md:container md:grid md:grid-cols-12 md:py-12 md:px-24">
                        <div className="md:col-span-2">
                            <Link href="/">
                                <Image src={`${imageStatic('logoweb.png')}`} width={150} height={49}
                                       className="cursor-pointer logo-web"/>
                            </Link>
                        </div>
                        <div className="md:col-span-5 header-item-font">
                            {/*<div className="grid grid-cols-5">*/}
                            {/*        <Link href='/about-us'>*/}
                            {/*            <div className="col-span-1 cursor-pointer">*/}
                            {/*                    <h3 className="text-base color__green text-center">*/}
                            {/*                        ABOUT US*/}
                            {/*                    </h3>*/}
                            {/*            </div>*/}
                            {/*        </Link>*/}
                            {/*    <div className="col-span-1 cursor-pointer">*/}
                            {/*        <h3 className="text-base color__green text-center">*/}
                            {/*            SERVICES*/}
                            {/*        </h3>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-1 cursor-pointer">*/}
                            {/*        <h3 className="text-base color__green text-center">*/}
                            {/*            ARTICLE*/}
                            {/*        </h3>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-span-1 cursor-pointer">*/}
                            {/*        <h3 className="text-base color__green text-center">*/}
                            {/*            CONTACT US*/}
                            {/*        </h3>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="md:col-span-5 md:mt-3">
                            <div className="flex items-center justify-end gap-x-8">
                                {/*<Link href="/cart-purchase-page">*/}
                                {/*<Menu as="div">*/}
                                {/*    <Menu.Button className="flex items-center">*/}
                                {/*        <Image width={29} height={29} src={`${imageStatic('icon/Vector-4.png')}`} className="cursor-pointer" />*/}
                                {/*    </Menu.Button>*/}
                                {/*    <Menu.Items*/}
                                {/*        as="ul"*/}
                                {/*        className="absolute p-2 text-lg mt-1 flex flex-col bg-white rounded-b-lg shadow-lg z-50"*/}
                                {/*    >*/}
                                {/*        <Menu.Item*/}
                                {/*            onClick={() => Router.push('/order-smm')}*/}
                                {/*            as="li"*/}
                                {/*            className="flex items-center p-2 cursor-pointer"*/}
                                {/*        >*/}
                                {/*            <img*/}
                                {/*                src={`${imageStatic('icon/bukti-bayar.png')}`}*/}
                                {/*                className="w-6 h-6 mr-2"*/}
                                {/*            />*/}
                                {/*            <span>Order SMM</span>*/}
                                {/*        </Menu.Item>*/}
                                {/*        <Menu.Item*/}
                                {/*            onClick={()=> Router.push("/cart")}*/}
                                {/*            as="li"*/}
                                {/*            className="flex items-center p-2 cursor-pointer"*/}
                                {/*        >*/}
                                {/*            <img*/}
                                {/*                src={`${imageStatic('icon/bukti-bayar.png')}`}*/}
                                {/*                className="w-6 h-6 mr-2"*/}
                                {/*            />*/}
                                {/*            <span>Order KOL</span>*/}
                                {/*        </Menu.Item>*/}
                                {/*    </Menu.Items>*/}
                                {/*</Menu>*/}
                                <div className="flex text-center gap-x-8">
                                    <div className="flex-auto">
                                        <Link href="/cara-kami-bekerja">
                                            <span className="font-14 font-bold text-mainTextGreen cursor-pointer pt-1 block">Cara Kerja Kami</span>
                                        </Link>
                                    </div>
                                    <div className="flex-auto">
                                        <Link href={ (asPath.includes('endorse') || asPath.includes('influencer') || asPath.includes('cart')) ? '/cart' : '/order-smm'}>
                                            <Image width={29} height={29} src={`${imageStatic('icon/Vector-4.png')}`}
                                                   className="cursor-pointer"/>
                                        </Link>
                                    </div>
                                </div>

                                <span className="border-r w-1 h-10"></span>

                                {/*<a onClick={toProfile}>*/}
                                {/*    <Image width={46} height={46} src={`${imageStatic('icon/Group-292.png')}`}*/}
                                {/*           className="cursor-pointer"/>*/}
                                {/*</a>*/}
                                <button
                                    className="md:text-sm hidden md:block text_color_green_2 md:py-2 border_color_green_2 md:text-center md:rounded-md md:w-1/2"
                                    type="button"
                                    onClick={()=> toProfile()}
                                >
                                    Profile
                                </button>
                            </div>
                        </div>
                    </div>
            </div>


            <div className="not-registered-navbar-mobile block md:hidden xl:hidden px-5 pt-6 pb-5 shadow-lg">
                <div className="grid grid-cols-12">
                    {/*<Hamburger/>*/}
                    <div className="col-span-3 pl-3">
                        <Link href="/">
                            <Image width={150} height={49} src={`${imageStatic('logoweb.png')}`} className="w-full"/>
                        </Link>
                    </div>
                    <div className="col-span-1">
                    </div>
                    <div className="col-span-8 pt-1">
                        <div className="flex flex-wrap relative justify-end">
                            <div className="pr-2 flex items-center">
                                {/*<Link href="/cart-purchase-page">*/}
                                {/*<Menu as="div">*/}
                                {/*    <Menu.Button className="flex items-center">*/}
                                {/*        <Image width={18} height={18} src={`${imageStatic('icon/Vector-4-mobile.png')}`} className="cursor-pointer" />*/}
                                {/*    </Menu.Button>*/}
                                {/*    <Menu.Items*/}
                                {/*        as="ul"*/}
                                {/*        className="absolute left-1 px-2 text-sm mt-1 flex flex-col bg-white rounded-b-lg shadow-lg z-10"*/}
                                {/*    >*/}
                                {/*        <Menu.Item*/}
                                {/*            onClick={() => Router.push('/order-smm')}*/}
                                {/*            as="li"*/}
                                {/*            className="flex items-center p-2"*/}
                                {/*        >*/}
                                {/*            <img*/}
                                {/*                src={`${imageStatic('icon/bukti-bayar.png')}`}*/}
                                {/*                className="w-4 h-4 mr-2"*/}
                                {/*            />*/}
                                {/*            <span>Order SMM</span>*/}
                                {/*        </Menu.Item>*/}
                                {/*        <Menu.Item*/}
                                {/*            onClick={()=> Router.push("/cart")}*/}
                                {/*            as="li"*/}
                                {/*            className="flex items-center p-2"*/}
                                {/*        >*/}
                                {/*                <img*/}
                                {/*                    src={`${imageStatic('icon/bukti-bayar.png')}`}*/}
                                {/*                    className="w-4 h-4 mr-2"*/}
                                {/*                />*/}
                                {/*                <span>Order KOL</span>*/}
                                {/*        </Menu.Item>*/}
                                {/*    </Menu.Items>*/}
                                {/*</Menu>*/}
                                <div className="flex text-center gap-x-4">
                                    <div className="flex-auto">
                                        <Link href="/cara-kami-bekerja">
                                            <span className="text-12px font-bold text-mainTextGreen cursor-pointer block">Cara Kerja Kami</span>
                                        </Link>
                                    </div>
                                    <div className="flex-auto">
                                        <Link href={ (asPath.includes('endorse') || asPath.includes('influencer') || asPath.includes('cart')) ? '/cart' : '/order-smm'}>
                                            <Image width={18} height={18} src={`${imageStatic('icon/Vector-4-mobile.png')}`} className="cursor-pointer" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 border-l flex items-center">
                                <a onClick={toProfile}>
                                    <Image width={18} height={18} src={`${imageStatic('icon/Group-292.png')}`}
                                           className="cursor-pointer"/>
                                </a>
                            </div>
                            <div className="pl-2 border-l flex items-center" onClick={handleLogout}>
                                <Image width={18} height={18} src={`${imageStatic('logout.png')}`}
                                       className="cursor-pointer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )

}

export default NavbarAfterLogin;
