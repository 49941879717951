export const GET_PAYMENT_METHOD_LIST = 'GET_PAYMENT_METHOD_LIST';
export const PAYMENT_ORDER_SMM = 'PAYMENT_ORDER_SMM';
export const LOADING_PAYMENT_ORDER_SMM = 'LOADING_PAYMENT_ORDER_SMM';
export const PAYMENT_ORDER_TIKTOK = 'PAYMENT_ORDER_TIKTOK';
export const LOADING_PAYMENT_ORDER_TIKTOK = 'LOADING_PAYMENT_ORDER_TIKTOK';
export const PAYMENT_ORDER_KOL = 'PAYMENT_ORDER_KOL';
export const LOADING_PAYMENT_ORDER_KOL = 'LOADING_PAYMENT_ORDER_KOL';
export const CHECK_ORDER = 'CHECK_ORDER';
export const GET_PAYOUT_BANK_LIST = 'GET_PAYOUT_BANK_LIST';
export const GET_USER_BANK_DATA = 'GET_USER_BANK_DATA';
