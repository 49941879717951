import {
    GET_USER_PROFILE,
    GET_USER_PROJECT,
    UPDATE_USER_PROFILE,
    GET_USER_ORDER_DETAIL,
    LOADING_USER_PROFILE,
    LOADING_UPDATE_USER_PROFILE,
    LOADING_USER_PROJECT,
    LOADING_USER_ORDER_DETAIL, GET_CHECK_ORDER_DATA,
} from '../../action/profile/types';

const initialState = {
    profile: {
        data: {},
        message: '',
        error: false,
        loading: false,
        isUpdating: false,
    },
    projects: {
        data: [],
        status: 'idle', // "idle" | "loading" | "completed" | "failed"
        message: '',
        error: null,
    },
    orderDetail: {
        data: {},
        status: 'idle', // "idle" | "loading" | "completed" | "failed"
        message: '',
        error: null,
    },
};

const dataProfile = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_CHECK_ORDER_DATA:
            return{
                ...state,
                profile: {
                    ...state.profile,
                    data: action.payload.data,
                    message: action.payload.message,
                    error: action.payload.error,
                },
            }
        case GET_USER_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    data: action.payload.data,
                    message: action.payload.message,
                    error: action.payload.error,
                },
            };
        case UPDATE_USER_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    message: action.payload.message,
                    error: action.payload.error,
                },
            };
        case LOADING_USER_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: !state.profile.loading,
                },
            };
        case LOADING_UPDATE_USER_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    isUpdating: !state.profile.isUpdating,
                },
            };
        case GET_USER_PROJECT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    data: action.payload.data,
                    message: action.payload.message,
                    error: action.payload.error ?? null,
                },
            };
        case LOADING_USER_PROJECT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    status: action.payload.status,
                },
            };
        case GET_USER_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: {
                    ...state.orderDetail,
                    data: action.payload.data,
                    message: action.payload.message,
                    error: action.payload.error ?? null,
                },
            };
        case LOADING_USER_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: {
                    ...state.orderDetail,
                    status: action.payload.status,
                },
            };
        default:
            return state;
    }
};

export default dataProfile;
