import {GETPRICESMMSTARTER} from "../../action/getOrderSMMStarter/types";

const initialState = {
    message: '',
    dataPriceSmmStarter: [],
};

const dataPriceSmmStarter = (state = initialState, action = {}) => {
    if (action.type === GETPRICESMMSTARTER) {
        return {
            ...state,
            dataPriceSmmStarter: action.payload.dataPriceSmmStarter,
            message: action.payload ? action.payload.message : state,
        }
    } else {
        return state
    }
}

export default dataPriceSmmStarter;
