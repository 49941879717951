import Link from 'next/link'
import ModalLogin from './login/loginModal';
import Image from 'next/image'
import {imageStatic} from "../helpers/image";

const NavbarBeforeLogin = () => {
    return(
        <div>
            <div className="not-registered-navbar hidden md:block md:shadow-lg md:absolute md:w-full md:z-50 bg_color_white_1">
                <div className="md:container md:grid md:grid-cols-12 md:py-12 md:px-24">
                    <div className="md:col-span-2">
                        <Link href="/">
                            <Image width={150} height={49} src={`${imageStatic('logoweb.png')}`}
                                   className="cursor-pointer logo-web"/>
                        </Link>
                    </div>
                    <div className="md:col-span-5 header-item-font">
                        {/*<div className="grid grid-cols-5">*/}
                        {/*    <div className="col-span-1 cursor-pointer">*/}
                        {/*        <Link href='/about-us'>*/}
                        {/*            <h3 className="text-base color__green text-center">*/}
                        {/*                ABOUT US*/}
                        {/*            </h3>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-span-1 cursor-pointer">*/}
                        {/*        <Link href='/influencer-page'>*/}
                        {/*            <h3 className="text-base color__green text-center">*/}
                        {/*                SERVICES*/}
                        {/*            </h3>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-span-1 cursor-pointer">*/}
                        {/*        <Link href='/'>*/}
                        {/*            <h3 className="text-base color__green text-center">*/}
                        {/*                ARTICLE*/}
                        {/*            </h3>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-span-1 cursor-pointer">*/}
                        {/*        <Link href='/contact-us'>*/}
                        {/*            <h3 className="text-base color__green text-center">*/}
                        {/*                CONTACT US*/}
                        {/*            </h3>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="md:col-span-5 md:mt-3">
                        <div className="md:grid md:grid-cols-12 gap-x-8 items-center">
                            <div className="md:col-span-5 items-center">
                                {/*<img src={`${imageStatic('icon/Frame-5.png')}`}*/}
                                {/*     className="cursor-pointer opacity-0"></img>*/}
                                {/*<Link href="/cart-purchase-page">*/}
                                <div className="flex text-center">
                                    <div className="flex-auto">
                                        <Link href="/cara-kami-bekerja">
                                            <span className="font-14 font-bold text-mainTextGreen cursor-pointer pt-1 block">Cara Kerja Kami</span>
                                        </Link>
                                    </div>
                                    <div className="flex-auto">
                                        <Link href="/order-smm">
                                            <img src={`${imageStatic('icon/Vector-4.png')}`} className="cursor-pointer d-block mx-auto"></img>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 relative h-full">
                                <span className="border-l h-full block"></span>
                            </div>

                            {/* navbar before */}
                            <div className="md:col-span-5">
                                <div className="md:grid md:grid-cols-1 md:gap-x-4 ">
                                    <div className="md:col-span-1">
                                        <ModalLogin/>
                                    </div>
                                    {/*<div className="md:col-span-1">*/}
                                    {/*    <Link href="/daftar-owner">*/}
                                    {/*        <button className="md:text-sm text_color_white bg_color_green md:py-2 md:text-center md:rounded-md md:w-full">*/}
                                    {/*            Register*/}
                                    {/*        </button>*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            {/* } */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="not-registered-navbar-mobile block md:hidden xl:hidden px-5 pt-6 pb-5 shadow-lg">
                <div className="grid grid-cols-12">
                    {/*<Hamburger/>*/}
                    <div className="col-span-3">
                        <Link href="/">
                            <Image width={150} height={49} src={`${imageStatic('logoweb.png')}`} className="w-full"/>
                        </Link>
                    </div>
                    <div className="col-span-1">
                    </div>
                    <div className="col-span-8 pt-1">
                        <div className="grid grid-cols-12 relative">
                            <div className="col-span-6 text-center">
                                <Link href="/cara-kami-bekerja">
                                    <span className="text-12px font-bold text-mainTextGreen cursor-pointer">Cara Kerja Kami</span>
                                </Link>
                            </div>
                            <div className="col-span-1">
                                {/*<Link href="/cart-purchase-page">*/}
                                <Link href="/order-smm">
                                    <img src={`${imageStatic('icon/Vector-4-mobile.png')}`} className=""></img>
                                </Link>
                            </div>
                            <div className="col-span-5 border-l justify-self-end">
                                <ModalLogin/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavbarBeforeLogin;
