import {GETPRICESMMPREMIUM} from "../../action/getOrderSMMPremium/types";

const initialState = {
    message: '',
    dataPriceSmmPremium: [],
};

const dataPriceSmmPremium = (state = initialState, action = {}) => {
    if (action.type === GETPRICESMMPREMIUM) {
        return {
            ...state,
            dataPriceSmmPremium: action.payload.dataPriceSmmPremium,
            message: action.payload ? action.payload.message : state,
        }
    } else {
        return state
    }
}

export default dataPriceSmmPremium;
