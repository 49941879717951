import {GETPROMO, LOADING_VERIFY_PROMO, VERIFYPROMO,} from '../../action/promo/types';

const initialState = {
    message: '',
    dataPromo: [],
    dataVerifyPromo: {
        data: [],
        message: '',
        loading: false,
        error: false,
    },
};

const dataPromo = (state = initialState, action = {}) => {
    switch (action.type) {
        case GETPROMO:
            return {
                ...state,
                dataPromo: action.payload.dataPromo,
                message: action.payload ? action.payload.message : state,
            };
        case VERIFYPROMO:
            return {
                ...state,
                dataVerifyPromo: {
                    ...state.dataVerifyPromo,
                    data: action.payload.dataVerifyPromo,
                    message: action.payload ? action.payload.message : state,
                    error: action.payload.error,
                },
            };
        case LOADING_VERIFY_PROMO:
            return {
                ...state,
                dataVerifyPromo: {
                    ...state.dataVerifyPromo,
                    loading: !state.dataVerifyPromo.loading,
                },
            };
        default:
            return state;
    }
};

export default dataPromo;
