import {GET_KOTA} from '../../action/verification-login/types';

const initialState = {
    dataKota: [],
};

const verificationLogin = (state = initialState, action = {}) => {
    if (action.type === GET_KOTA) {
        return {
            ...state,
            dataKota: action.payload,
        };
    } else {
        return state;
    }
};

export default verificationLogin;
