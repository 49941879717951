import {GET_BIDANG_USAHA,} from "../../action/bidangUsaha/types";

const initialState = {
    dataMaster: []
};

const bidangUsaha = (state = initialState, action = {}) => {

    if (action.type === GET_BIDANG_USAHA) {
        return {
            ...state,
            dataMaster: action.payload.data,
        }
    } else {
        return state
    }
}

export default bidangUsaha;
