import {GET_KOL_INFLUENCER,} from "../../action/kolInfluencer/types";


const initialState = {
    getKolInfluencerResult: false,
    getKolInfluencerLoading: false,
    getKolInfluencerError: false
};

const KolInfluencer = (state = initialState, action = {}) => {

    if (action.type === GET_KOL_INFLUENCER) {
        return {
            ...state,
            getKolInfluencerResult: action.payload.data,
            getKolInfluencerLoading: action.payload.loading,
            getKolInfluencerError: action.payload.errorMessage
        }
    } else {
        return state
    }
}

export default KolInfluencer;
