import {GETINFLUENCER, GETINFLUENCERPAGINATION} from "../../action/influencer/types";

const initialState = {
    message: '',
    dataInfluencer: [],
    dataInfluencerPagination: []

};

const dataInfluencer = (state = initialState, action = {}) => {

    switch (action.type) {
        case GETINFLUENCER:
            return {
                ...state,
                dataInfluencer: action.payload.dataInfluencer,
                message: action.payload ? action.payload.message : state,
            }
        case GETINFLUENCERPAGINATION:
            return {
                ...state,
                dataInfluencer: [...state.dataInfluencer, ...action.payload.dataInfluencerPagination],
            }
        default :
            return state
    }
}

export default dataInfluencer;
