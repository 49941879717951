import Footer from '../../src/components/footer'
import Navbar from '../../src/components/navbar'
import Meta from "../../constants/meta";
import {BrowserView, MobileView} from 'react-device-detect';
import dynamic from 'next/dynamic'
import {ModalHome} from "../../src/components/Modal/ModalHome";
import {useEffect, useState} from "react";

const HomepageDesktop = dynamic(() => import('../../src/components/StaticPage/Dekstop/homepage'))
const HomepageMobile = dynamic(() => import('../../src/components/StaticPage/Mobile/homepage'))

const HomePage = () => {

    const [modal, setModal] = useState(true)
    useEffect(function() {
        const data = window.localStorage.getItem('modal-home')
        if (data) {
            setModal(false)
        }
    },[]);

    return (
        <div>
            <Meta
                title="Marketplace Influencer untuk UMKM | socialbread.id"
                description="Situs penyedia layanan endorse marketing kol influencer, cari kol influencer terbaik, jasa social media management service, harga terjangkau social bread"
                keywords="kol influencer endorse marketing, social media management service, endorse terbaik, endorse terpercaya, solusi endorse"
                ogTitle="KOL influencer endorse & social media management business | socialbread"
                ogDescription="Situs endorse KOL influencer berkualitas & social media management terpercaya"
            />
            <Navbar/>

            {modal && <ModalHome setModal={setModal} modal={modal}/>}
            <BrowserView><HomepageDesktop/></BrowserView>
            <MobileView><HomepageMobile/></MobileView>

            <Footer/>
        </div>
    )
}


export default HomePage;
