import {combineReducers} from 'redux';
import aboutUsReducer from './aboutUs';
import semWpReducer from './semWp';
import faqWpReducer from './faqWp';
import contactUsReducer from './contactUs';
import kolInfluencerReducer from './kolInfluencer';
import loginUserReducer from './login';
import loginUserOtpReducer from './login/otp';
import homePageReducer from './homePage';
import sliderHomePageReducer from './sliderHomepage';
import masterBidangUsahaReducer from './bidangUsaha';
import influencerReducer from './influencer';
import influencerDetailReducer from './influencerDetail';
import influencerFilterReducer from './influencer/filter-mobile';
import kategoriUsahaReducer from './filter';
import dataPromoReducer from './promo';
import getPriceSmmReducer from './orderSmm';
import getPriceSmmNoIGReducer from './orderSMMNoIG';
import getPriceSmmPremiumReducer from './orderSMMPremium';
import getPriceSmmCompleteReducer from './orderSMMComplete';
import getPriceSmmStarterReducer from './orderSMMStarter';
import verificationLoginReducer from './verification-login';
import paymentReducer from './payment';
import profileReducer from './profile';
import addToCartReducer from './addToCart';
import eventThrReducer from './eventThr';

export default combineReducers({
    aboutUsReducer,
    semWpReducer,
    faqWpReducer,
    contactUsReducer,
    kolInfluencerReducer,
    loginUserOtpReducer,
    loginUserReducer,
    homePageReducer,
    sliderHomePageReducer,
    masterBidangUsahaReducer,
    influencerReducer,
    influencerDetailReducer,
    influencerFilterReducer,
    kategoriUsahaReducer,
    dataPromoReducer,
    getPriceSmmReducer,
    getPriceSmmNoIGReducer,
    getPriceSmmPremiumReducer,
    getPriceSmmCompleteReducer,
    getPriceSmmStarterReducer,
    verificationLoginReducer,
    paymentReducer,
    profileReducer,
    addToCartReducer,
    eventThrReducer
});
