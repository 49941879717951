import Link from 'next/link'

export const ButtonHomePagetoSMM = (props) => {
    return(
        <>
            <Link href="/social-media-management">
                <a className='bg_color_cream_1 text md:text-4xl py-5 px-16 block text-center rounded-full font-bold color__green inline-block shadow-button-1 block mb-4'>
                    Coba Sekarang
                </a>
            </Link>

        </>
    )
}
