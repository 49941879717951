import Cookies from 'js-cookie';
import _ from 'lodash';
import { toast } from 'react-hot-toast';

const requestMiddleware = ({dispatch, getState}) => (next) => {

  return (action) => {
   if(action.payload) {
    const isError = action.payload.error
    if(isError) {
      const err_msg = _.isBoolean(isError) ? action.payload.message : isError
      toast.error(err_msg)
      if(err_msg === "Failed to verify token") {
        // 401
        Cookies.remove("token");
        Cookies.remove("role");
        window.location.href= "/login";
      }
    } else {
      next(action);
    }
   } else {
    next(action);
   }    
  }
}

export default requestMiddleware