import getConfig from 'next/config'

const {publicRuntimeConfig} = getConfig()
const {BE_APPS} = publicRuntimeConfig
const {WORDPRESS_BE} = publicRuntimeConfig

export const API_WORDPRESS = {
    baseURL: process.env.WORDPRESS_BE,
    headers: {
        "Content-type": "application/json"
    }
};

export const API_BE = {
    baseURL: process.env.BE_APPS,
    headers: {
        "Content-type": "application/json"
    }
};