import {GET_SLIDER_HOMEPAGE_WP,} from "../../action/sliderHomepage/types";

const initialState = {
    getsliderHomepageResult: false,
    getsliderHomepageLoading: false,
    getsliderHomepageError: false
};


const sliderHomepage = (state = initialState, action = {}) => {

    if (action.type === GET_SLIDER_HOMEPAGE_WP) {
        return {
            ...state,
            getsliderHomepageResult: action.payload.data,
            getsliderHomepageLoading: action.payload.loading,
            getsliderHomepageError: action.payload.errorMessage
        }
    } else {
        return state
    }
}

export default sliderHomepage;
