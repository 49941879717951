import {isDesktop, isMobileOnly, isTablet} from 'react-device-detect';
import Link from 'next/link'
import Image from 'next/image'
import {imageStatic} from "../helpers/image";

function Footer(){
    return(
        <div>
            {isDesktop ? <>
                <div className="bg_color_green_5 pt-11 pb-11 md:py-16 md:z-10">
                    <div className="px-4 md:container md:mx-auto md:px-0">
                        <div className="md:grid md:grid-cols-10">
                            <div className="md:col-span-12">
                                <div className="grid md:grid-cols-5 grid-cols-5">
                                    <div className="md:col-span-1 col-span-4">
                                        <Image width={219} height={70} src={`${imageStatic('Frame-2.png')}`}
                                               className="w-36 pl-0 md:pl-8 mb-7 md:w-3/4"/>
                                    </div>
                                    <div className="md:col-span-1 col-span-2 pl-0 md:pl-0">
                                        <h3 className="text_color_cream_1 text-sm mb-2 md:text-2xl font-semibold md:mt-2 md:mb-7">
                                            Company
                                        </h3>
                                        <Link href="/about-us">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                About Us
                                            </a>
                                        </Link>
                                        <Link href="/social-media-management">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                Our Service
                                            </a>
                                        </Link>
                                        <Link href="/newsroom">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                Press
                                            </a>
                                        </Link>
                                        {/* <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                            Article
                                        </a> */}
                                    </div>
                                    <div className="md:col-span-1 col-span-2">
                                        <h3 className="text_color_cream_1 text-sm mb-2 md:text-2xl font-semibold md:mt-2 md:mb-7">
                                            Get in Touch
                                        </h3>
                                        <Link href="/faq">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                FAQ
                                            </a>
                                        </Link>
                                        <Link href="/contact-us">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                Contact Us
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="md:col-span-1 col-span-2 pl-0 md:pl-0">
                                        <h3 className="text_color_cream_1 text-sm mt-7 mb-2 md:text-2xl font-semibold md:mt-2 md:mb-7">
                                            Connect With Us
                                        </h3>
                                        <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 mr-5 inline-block" href="https://www.instagram.com/social.bread/" target="_blank">
                                            <Image width={34} height={35} src={`${imageStatic('icon/Icon-Instagram-2.png')}`}/>
                                        </a>
                                        <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 mr-5 inline-block" href="https://www.tiktok.com/@social.bread" target="_blank">
                                            <Image width={26} height={29} src={`${imageStatic('icon/Vector-3.png')}`} className="-mt-8"/>
                                        </a>
                                        <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://www.youtube.com/channel/UCBnUwBezR0941aQ3tC8KpRQ" target="_blank">
                                            <Image width={40} height={33} src="/youtube.png" className="-mt-8"/>
                                        </a>
                                    </div>
                                    <div className="md:col-span-1 col-span-2 pl-0 md:pl-0">
                                        <h3 className="text_color_cream_1 text-sm mt-7 mb-2 md:text-2xl font-semibold md:mt-2">
                                            Want to be a Creator?
                                        </h3>
                                        <div className='flex flex-col'>
                                            <span className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 mr-5 inline-block">Install our Partners App!</span>
                                            <div className='flex flex-row gap-3'>
                                                <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://apps.apple.com/us/app/sb-partners/id6449588712" target="_blank">
                                                    <Image width={150} height={60} src="/appstore.svg" className="-mt-8"/>
                                                </a>
                                                <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://play.google.com/store/apps/details?id=id.socialbread.creator" target="_blank">
                                                    <Image width={150} height={60} src="/playstore.svg" className="-mt-8"/>
                                                </a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="text_color_cream_1 text-xs md:text-lg text-left md:text-center mt-8 md:mt-12">
                            All Right Reserved 2023 &copy;  PT. Sosial Berkat Kreatif Indonesia
                        </p>
                    </div>
                </div>
            </>
                        : <></>}

            {isTablet ? <>
                <div className="bg_color_green_5 pt-11 pb-11 md:py-16 md:z-10">
                    <div className="px-4 md:container md:mx-auto md:px-0">
                        <div className="md:grid md:grid-cols-10">
                            <div className="md:col-span-7">
                                <div className="grid md:grid-cols-5 grid-cols-5">
                                    <div className="md:col-span-1 col-span-4">
                                        <Image width={218} height={41} src={`${imageStatic('Frame-2.png')}`}
                                               className="w-36 pl-0 md:pl-8 mb-7 md:w-3/4"/>
                                    </div>
                                    <div className="md:col-span-1 col-span-2 pl-0 md:pl-0">
                                        <h3 className="text_color_cream_1 text-sm mb-2 md:text-2xl font-semibold md:mt-2 md:mb-7">
                                            Company
                                        </h3>
                                        <Link href="/about-us">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                About Us
                                            </a>
                                        </Link>
                                        <Link href="/social-media-management">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                Our Service
                                            </a>
                                        </Link>
                                        <Link href="/newsroom">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                Press
                                            </a>
                                        </Link>
                                        {/* <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                            Article
                                        </a> */}
                                    </div>
                                    <div className="md:col-span-1 col-span-2">
                                        <h3 className="text_color_cream_1 text-sm mb-2 md:text-2xl font-semibold md:mt-2 md:mb-7">
                                            Get in Touch
                                        </h3>
                                        <Link href="/faq">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                FAQ
                                            </a>
                                        </Link>
                                        <Link href="/contact-us">
                                            <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 block" >
                                                Contact Us
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="md:col-span-1 col-span-2 pl-0 md:pl-0">
                                        <h3 className="text_color_cream_1 text-sm mt-7 mb-2 md:text-2xl font-semibold md:mt-2 md:mb-7">
                                            Connect With Us
                                        </h3>
                                        <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 mr-5 inline-block" href="https://www.instagram.com/social.bread/" target="_blank">
                                            <Image width={29} height={29}
                                                   src={`${imageStatic('icon/Icon-Instagram-2.png')}`}/>
                                        </a>
                                        <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://www.tiktok.com/@social.bread" target="_blank">
                                            <Image width={29} height={29} src={`${imageStatic('icon/Vector-3.png')}`}
                                                   className=""/>
                                        </a>
                                    </div>
                                    <div className="md:col-span-1 col-span-2 pl-0 md:pl-0">
                                        <h3 className="text_color_cream_1 text-sm mt-7 mb-2 md:text-2xl font-semibold md:mt-2">
                                            Want to be a Creator?
                                        </h3>
                                        <div className='flex flex-col'>
                                            <span className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 mr-5 inline-block">Install our Partners App!</span>
                                            <div className='flex flex-col gap-3'>
                                                <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://www.youtube.com/channel/UCBnUwBezR0941aQ3tC8KpRQ" target="_blank">
                                                    <Image width={150} height={60} src="/appstore.svg" className="-mt-8"/>
                                                </a>
                                                <a className="text_color_cream_2 text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://play.google.com/store/apps/details?id=id.socialbread.creator" target="_blank">
                                                    <Image width={150} height={60} src="/playstore.svg" className="-mt-8"/>
                                                </a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="text_color_cream_1 text-xs md:text-lg text-left md:text-center mt-8 md:mt-12">
                            All Right Reserved 2023 &copy;  PT. Sosial Berkat Kreatif Indonesia
                        </p>
                    </div>
                </div>
            </>
                        : <></>}

            {isMobileOnly ? <>
                <section className='footer-mobile-area'>
                    <div className='md:container mx-auto border-b border-green-2'>
                        <h3 className='text-sm mb-2 font-bold color__green pt-2'>
                            Mau Tau Tentang Sosial Bread ?
                        </h3>
                        <ul className='text-sm mb-2'>
                            <li className='inline-block mb-1'>
                                <Link href="/about-us">
                                    <a className='border-r pr-1 leading-none color__green border-green-2'>
                                        Tentang Kami
                                    </a>
                                </Link>
                            </li>
                            <li className='inline-block mb-1'>
                                <Link href="/contact-us">
                                    <a className='border-r pr-1 pl-1 leading-none color__green border-green-2'>
                                        Hubungan Kami
                                    </a>
                                </Link>
                            </li>
                            {/*<li className='inline-block mb-1'>*/}
                            {/*    <p className='border-r pr-1 pl-1 leading-none color__green border-green-2'>*/}
                            {/*        Jasa Kami*/}
                            {/*    </p>*/}
                            {/*</li>*/}
                            <li className='inline-block mb-1'>
                                <Link href="/endorse-page">
                                    <a className='border-r pr-1 pl-1 leading-none color__green border-green-2'>
                                        Endorse Influencer
                                    </a>
                                </Link>
                            </li>
                            <li className='inline-block mb-1'>
                                <Link href="/social-media-management">
                                    <a className='border-r pr-1 pl-1 leading-none color__green border-green-2'>
                                        Manajemen Sosial Media
                                    </a>
                                </Link>
                            </li>
                            <li className='inline-block mb-1'>
                                <Link href="/newsroom">
                                    <a className='pl-1 leading-none color__green'>
                                        Press
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="md:col-span-1 col-span-2 pl-0 md:pl-0 mx-auto md:container border-b border-green-2">
                        <h3 className="color__green text-sm mt-2 mb-2 md:text-2xl font-semibold md:mt-2">
                            Want to be a Creator?
                        </h3>
                        <div className='flex flex-col'>
                            <span className="color__green text-xs mb-2 md:text-lg md:mb-3 mr-5 inline-block">Install our Partners App!</span>
                            <div className='flex flex-row gap-3'>
                                <a className="color__green text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://apps.apple.com/us/app/sb-partners/id6449588712" target="_blank">
                                    <Image width={150} height={60} src="/appstore.svg" className="-mt-8"/>
                                </a>
                                <a className="color__green text-xs mb-2 md:text-lg md:mb-3 inline-block" href="https://play.google.com/store/apps/details?id=id.socialbread.creator" target="_blank">
                                    <Image width={150} height={60} src="/playstore.svg" className="-mt-8"/>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                    <div className='md:container mx-auto'>
                        <ul className='text-sm mt-2 mb-7'>
                            <li className='inline-block mb-1'>
                                <Link href="/faq">
                                    <a className='border-r pr-1 pl-1 leading-none color__green'>
                                        FAQ
                                    </a>
                                </Link>
                            </li>
                        </ul>
                        <h3 className='text-sm mb-2 font-bold color__green'>
                            Sosial Bread
                        </h3>
                        {/*<div className='w-60 bg_color_grey_4 p-1 rounded-4 mb-5'>*/}
                        {/*    <button className='w-1/2 font-semibold color-white-1 bg_color_green_3 rounded-4 py-1'>*/}
                        {/*        Indonesia*/}
                        {/*    </button>*/}
                        {/*    <button className='w-1/2 font-semibold color__green rounded-4 py-1'>*/}
                        {/*        English*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <p className='text-sm color__green'>
                            ©2021-2022, PT.Sosial Berkat Kreatif Indonesia.
                        </p>
                    </div>
                </section>
            </>
                : <></>}
        </div>
    )
}

export default Footer;
