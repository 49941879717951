import {GET_HOMEPAGE_WP,} from "../../action/homePage/types";

const initialState = {
    gethomePageResult: false,
    gethomePageLoading: false,
    gethomePageError: false
};


const homePage = (state = initialState, action = {}) => {

    if (action.type === GET_HOMEPAGE_WP) {
        return {
            ...state,
            gethomePageResult: action.payload.data,
            gethomePageLoading: action.payload.loading,
            gethomePageError: action.payload.errorMessage
        }
    } else {
        return state
    }
}

export default homePage;
