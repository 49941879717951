import 'tailwindcss/tailwind.css';
import "../src/styles/global.scss";
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension';
import reducers from '../src/redux/reducer'
import {useEffect} from "react";
import TagManager from 'react-gtm-module';
import Meta from "../constants/meta";
import requestMiddleware from '../src/redux/requestMiddleware';
import {Toaster} from 'react-hot-toast';

function _app({Component, pageProps}) {
  useEffect(() => {
    TagManager.initialize({gtmId: 'GTM-MDWRBGT'});
  }, []);
  return <Provider store={createStore(reducers, composeWithDevTools(applyMiddleware(thunk, requestMiddleware)))}>
    <Meta
        title="Marketplace Influencer untuk UMKM | socialbread.id"
        description="Situs penyedia layanan endorse marketing kol influencer, cari kol influencer terbaik, jasa social media management service, harga terjangkau social bread"
        keywords="kol influencer endorse marketing, social media management service, endorse terbaik, endorse terpercaya, solusi endorse"
        ogTitle="KOL influencer endorse & social media management business | socialbread"
        ogDescription="Situs endorse KOL influencer berkualitas & social media management terpercaya"
    />
    <Toaster/>
    <Component {...pageProps} />
  </Provider>
}

export default _app;
