import {GETPRICESMMNOIG} from "../../action/getOrderSMMNoIG/types";

const initialState = {
    message: '',
    dataPriceSmmNoIG: [],
};

const dataPriceSmmNoIG = (state = initialState, action = {}) => {
    if (action.type === GETPRICESMMNOIG) {
        return {
            ...state,
            dataPriceSmmNoIG: action.payload.dataPriceSmmNoIG,
            message: action.payload ? action.payload.message : state,
        }
    } else {
        return state
    }
}

export default dataPriceSmmNoIG;
