export const imageStatic = (data) => {
    return process.env.CDN_URL + 'image-fe/' + data;
};

export const imagefromAPIBE = (data) => {
    return process.env.CDN_URL + data;
};

export const imageDynamicFromAdmin = (data) => {
    return process.env.CDN_URL + 'admin_panel/' + data;
};