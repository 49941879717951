import { CHECK_USER_CLAIM, GET_THR_LIST } from "../../action/eventThr/types";

const initialState = {
    getThrResult: false,
    getThrLoading: false,
    getThrError: false,
    isClaimed: true
};


const eventThrReducer = (state = initialState, action = {}) => {
    if (action.type === GET_THR_LIST) {
        return {
            ...state,
            getThrResult: action.payload.dataListThr,
            getThrLoading: action.payload.loading,
            getThrError: action.payload.errorMessage
        }
    } else if (action.type === CHECK_USER_CLAIM) {
        return {
            ...state,
            isClaimed: action.payload.data
        }
    } else {
        return state
    }
}

export default eventThrReducer;
