import {useRouter} from "next/router";

export const ModalHome = (props) => {
const {setModal, modal} = props;
    const router = useRouter();

const no = () => {
setModal(false);
    localStorage.setItem("modal-home", true);
}
const yes = () => {
    window.location.href = "/cara-kami-bekerja"
    localStorage.setItem("modal-home", true);
}
    return(
        <>
            {modal ? (
                <div className="modal fixed top-0 right-0 left-0 bottom-0 z-50  flex justify-center items-center">
                    <div className="absolute bg-black top-0 right-0 left-0 bottom-0  opacity-70 z-0 "></div>
                    <div className="sm:max-w-max max-w-sm modal-content bg-mainGreen text-center rounded-22px sm:py-77px py-54px sm:px-63px px-38px relative z-10">
                        <div className="modal-body">
                            <p className="sub-mob-1 text-white font-medium mb-25px">Apakah anda baru pertama kali menggunakan Social Bread?</p>
                        </div>
                        <div className="modal-footer flex justify-center gap-3 sm:flex-row flex-col">
                            <button onClick={yes} className="sm:w-218px w-full h-50px rounded-10px bg-mainTextGreen font-semibold text-white sub-mob-2">
                                Ya, pelajari dulu
                            </button>
                            <button onClick={no} className="sm:w-218px w-full h-50px rounded-10px bg-white font-semibold text-mainTextGreen sub-mob-2">
                                Saya sudah mengerti
                            </button>

                        </div>
                    </div>
                </div>
            ) : null}


        </>
    )
}