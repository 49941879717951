import {AUTHENTICATE_ERROR_OTP, AUTHENTICATE_OTP, AUTHENTICATE_STATUS_OTP} from "../../action/login/types";


const initialState = {
    message: '',
    next: false,
    first_login: null
};

const loginUsersOtp = (state = initialState, action = {}) => {

    switch (action.type) {

        case AUTHENTICATE_OTP:
            return {
                ...state,
                message: action.payload ? action.payload.message : state,
                next: true,
                first_login: action.payload.first_login
            }
        case AUTHENTICATE_ERROR_OTP:
            return {
                ...state,
                message: action.payload ? action.payload.message : state,
                next: false
            }
        case AUTHENTICATE_STATUS_OTP:
            return {
                ...state,
            }
        default :
            return state
    }
}

export default loginUsersOtp;
