import axios from 'axios';
import {GET_KOTA} from './types';
import {firstLoginUrl, kotaUrl} from '../../../service/verification-login';
import Cookies from 'js-cookie';

export const fetchDataKotaAction = () => {
    return (dispatch) => {
        axios
            .get(kotaUrl)
            .then((response) => {
                const res = response.data;
                const datas = res.data;

                localStorage.setItem("dataKota", JSON.stringify(datas));
                dispatch({
                    type: GET_KOTA,
                    payload: datas,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const firstLoginUser = (data) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(firstLoginUrl, data);
            const result = response.data.data;
            const {message, token, role} = result;
            Cookies.set('token', token, {secure: true}, {expires: 7, domain: window.location.hostname});
            Cookies.set('role', role, {secure: true}, {expires: 7});

            return {
                message,
                error: false,
            };
        } catch (error) {
            const { message } = error.response.data;

            return {
                message,
                error: true,
            };
        }
    };
};
